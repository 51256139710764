.playlistToolbar {
    background-color: var(--grey-darker);
    min-height: 35px !important;
}

.playlist_bloc {
    position: relative;
    z-index: 0;
    margin:0 !important;
    .MuiAccordionDetails-root {
        background: var(--main-color);
    }
    .MuiAccordionSummary-expandIconWrapper {
        z-index: 5; 
    }
    .MuiListItemButton-root{
        background: rgb(79,79,79);
        background: linear-gradient(180deg, rgba(79,79,79,1) 71%, rgba(85,85,85,1) 100%);
    }

    &.mediaUnavailable .MuiListItemButton-root{
        background: rgb(57, 43, 43);
        background: linear-gradient(180deg, rgb(57, 43, 43) 71%, rgb(30, 23, 23) 100%);
    }
}

.mediaForbiddenIcon {
    display: inline;
    margin-left: 0.5px;
    margin-top: -1em;
    color: var(--red);
    font-size: 23px;
    background: #392b2b;
}

#mediaInfoDrawer {
    .MuiPaper-root {  
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
    }
    .MuiAccordionSummary-content {
        margin:5px 0 !important;
    } 
    .Mui-expanded{
        min-height: 20px !important;
    }
}

.DrawerBackButton {
    background: var(--grey-lighter);
    cursor: pointer;

    &::before {
        content:'';
        width: 50px;
        height: 5px;
        background: var(--grey-light);
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 7px;
        margin-bottom: 7px;
        border-radius: 14px;
    }
}

.DrawerMediaLinearProgress {
    background-color: white !important;
}

.DrawerGradient {
    position: absolute;
    background-size: cover;
    background-position: 50% 50%;
    width: 80%;
    height: 110%;
    z-index: 3;
    top: 20px;
    left: 20%;
    
    &::before {
        content:'';
        background-image: linear-gradient(to right, white 20%, rgba(255,255,255,0.4) 100%);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 4;
    }

    +ul {
        z-index: 5;
    }
}

@media (max-width: 900px) { 
    .DrawerGradient {    
        background-position: 0px -42px;
    }

    .playlistToolbar {
        padding-left: 1.5em;
    }
}

.mediaDescriptionAccordion {
    box-shadow: none !important;
    background: rgba(255,255,255,0.4) !important;
    border-left: 2px solid var(--blue-light);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    >div {
        padding-left: 0.5em !important;
    }
}