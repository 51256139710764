#root {
    background:var(--grey-dark);
    max-width: 100vw;
    min-height: 100vh;
    background-size: cover;
    background-position: 50% 50%;
    padding-top: 0vw;
    > div:not(.rnc__base) {
      border-radius: 0;
      min-height: 100vh;
      overflow-x: hidden;
      max-width: 100vw;
    }
}

body,html {
  margin: 0 !important;
  padding: 0 !important;
}

.colorOrange {
  color: var(--orange) !important;
}

.colorGreen {
  color: var(--green-light) !important;
}
.colorGreen2 {
  color: var(--green-2) !important;
}

.colorRed {
  color: var(--red) !important;
}
.colorRed2 {
  color: var(--red-2) !important;
}

.colorBlue {
  color: var(--blue-light) !important;
}

.colorWhite, 
.colorWhite svg {
  color:var(--white) !important;
  fill:var(--white) !important;
}

.textCapitalize {
  text-transform: uppercase;
}

.firstLetterCapitalize {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

.scroll  {
  scrollbar-width:thin !important;
  scroll-behavior: smooth;
  margin-bottom: 8em;
}


.blurtextura {
  background-image: url('../img/circles.png');
  background-size: contain;
}

.MuiDialog-container .flexRowCenterH {
  background-image:url('../img/textura.png');
  background-size: cover;
  position: relative;
}

.flexRowCenterH {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  svg {
    z-index: 2;
    margin-right: 1em;
  }
}
.flexRowCenterHDirectChild>* {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.max-lined {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-line-1 {
  -webkit-line-clamp: 1;
}

.max-line-2 {
  -webkit-line-clamp: 2;
}

.max-line-3 {
  -webkit-line-clamp: 3;
}

.hidden, .jss5 {
  display: none !important;
}

.jss1 {
  padding: 0 !important;
}

.hiddenButPresent {
  opacity: 0 !important;
  height: 0 !important;
  overflow: hidden;
}

h1,h2,h3,h4,h5,span {
    color: var(--main-color) !important;
}

b, * {
  font-family: var(--main-typo-family), sans-serif;
}

.MuiInputAdornment-root {
    background-color: var(--main-color) !important;
}

.main_bg_color {
  background-color: var(--main-color) !important;

  &:hover {
    background-color: var(--main-color-darker) !important;
  }
  span {
    color: var(--white) !important;
  }
}

.buttonBorder {
  
  border-color:var(--main-color-darker) !important;
  border-style: solid !important;
  border-width: 4px !important;
} // first button
.border {
  border-color:var(--main-color-darker);
  border-style: solid;
  border-width: 4px;
}
  .bordNormal {
    border-color:var(--main-color);
  }
  .bordLight {
    border-color:var(--main-color-lighter);
  }

  .bordGreen {
    border-color: var(--green-2);
  }

  .bordGreenLight {
    border-color: var(--green-light);
  }
  .bordRed {
    border-color: var(--red);
  }
  .bordOrange {
    border-color: var(--orange);
  }

  .bordRedLight {
    border-color: var(--red-2);
  }

  .bord1 {
    border-width: 1px;
  }
  .bord2 {
    border-width: 2px;
  }
  .bord3 {
    border-width: 3px;
  }
  .bord4 {
    border-width: 4px;
  }

  .bordDash {
    border-style: dashed;
  }
  .bordSolid {
    border-style: solid;
  }


.btnIconFixToLeft .MuiButton-startIcon{
  position: absolute;
  left: 4%;
  transition: var(--transition-smooth-all);
}

.btnIconFixToLeft:hover .MuiButton-startIcon{
  left: calc(100% - 7%);
}
.borderMainColor {
  border-color: var(--main-color) !important;
}


.MuiDialogTitle-root.flexRowCenterH {
  color: var(--grey-dark) !important;
  background: url('../img/textura.png');
  background-size: 100%;
  .iconify {
    transform: scale(1.5);
    margin-left: 0.3em;
  }
}

.textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.playlistAdminPass {
  color: var(--white) !important;
  background: var(--main-color);
  padding: 5px;
  width: 100%;
  display: block;
  border-radius: 10px;
  text-align: center;
  font-size: 3em;
  border: 3px dashed var(--main-color-darker);
}

// NOTIFS
.rnc__base {
  min-width: 190px !important;

  >div {   
    min-width: 190px !important;
    display: flex;
    flex-direction: column;
    align-items: end;
    .rnc__notification {
      width: auto !important;
      cursor: pointer;
      max-width: 50vw;
    }

    .rnc__notification-item {
      background-image:url('../img/textura.png');
      background-size: 200%;
    }

    .rnc__notification-content {
      padding: 3px 5px !important;  
      
      .rnc__notification-title {
        margin: 0;  
        margin-right: 15px;
        font-size: 14px;
        font-family: var( --title-typo-alt);
      }

      p {
        margin: 0;    
        font-size: 12px;
        line-height: 12px;
        font-family: var( --title-text-nunito);
      }

      .rnc__notification-timer {
        margin-top: 5px;
      }

      .rnc__notification-close-mark {
        background: transparent;
        &::after {
          font-size: 15px;
          right: -50%;
          top:-5%;
          left: auto;
        }
      }
    }
  }
}