.fullscreenLayout {
    .playerContainer .react-player {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;  
        
        background-image:url('../img/textura_white.png');
        background-size: cover;  
        background-color: rgba(0, 0, 0, 1);
        align-items: center;
        display: flex;
        > div {
            max-height: 100vh;
        }
        +div {
            z-index: 99999;
            top: 0px;
            position: fixed;
            height: calc(100% - 53px);
        }
    }

    .player_button_container {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9999;
        padding: 0;
    }

    .playerButtons {
        padding: 0;
    }

    .iconOverPlayer {  
        position: absolute;
        z-index: 10000;
        width: 100%;
        text-align: center;
        height: 80vh;
        line-height: 100%;
        top: 0;
        padding-top: 40vh;
    }

    .playerSection .playerContainer {
        overflow: visible;
    }
}

#root:has(.fullscreenLayout) {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

/*
*
* 600 PX IS THE BREAKPOINT WHERE MEDIA PASS ON TOP OF TITLE
*
*/


@media (max-width: 600px) { 
    .defaultLayout {
        .player_right_side_container {
            position: relative;
            .player_button_container {
                    padding: 0;
                    position: absolute;
                    top: -55px;
                    margin: 0;
                    left: 0;
                    height: 55px;
                    padding-top: 15px;
                    overflow: hidden;
                    >div {
                        height: 45px;
                    }
                    .playerButtons {
                        padding: 0;
                        margin-top: -2px;
                    }
            }
        }
        
        &.guestView {
            
            .player_right_side_container {
                .player_button_container {
                    >div {
                        top: -44px;
                    }
                }
            }
        }
    }
}

@media (min-width: 600px) { 
    .defaultLayout {
        
        .playerSection .player_right_side_container {
            overflow: hidden;
            .player_button_container {
                position: initial;
                width: 65%;    
                align-content: flex-end;
                padding-bottom: 15px;
                >div {
                    background: transparent;
                    padding-top: 25px;
                    .playerButtons {
                        gap: 15px;
                        >* {
                            transform: scale(1);
                        }
                        .mediaPlayingBar {
                            display: none;
                        }
                        .mediaPlayingBarSoundWave {
                            display: none;
                        }
                    }
                }
            }
        }

    }
}

@media (min-width: var(--breakpt-tablet)) { 
    .defaultLayout {
        .player_button_container {
            >div {
                .playerButtons >* {
                    transform: scale(1.2);
                }
            }
        }
    }
}

/* TRICK TO HIDE YOUTUBE OVERLAY */
.defaultLayout {
    .react-player {
        >div {
                transition: all 0.3 var(--transition-fast-easeIn);

                overflow: hidden;
                width: 100%;
                /* Keep it the right aspect-ratio */
                aspect-ratio: 16/9;
                /* No clicking/hover effects */
                pointer-events: none;
            iframe {
                width: 300%;
                height: 100%;
                margin-left: -100%;
            }
        }
    }
}
