.playerSection {

    /*
    *
    * LEFT SIDE CONTAINER (TOP ON PHONE) = VIDEO / MEDIA PLAYER
    *
    */

    .playerContainer {
        position: relative;
        overflow: hidden;
        min-height: 150px;
        
        background-image:url('../img/textura_white.png');
        background-size: cover;  
        background-color: rgba(21, 10, 10, 0.74);

        .react-player { 
            > div {
                max-height: 240px
            }
            +div {
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 8;
                position: absolute;
                top: 0;
                cursor: pointer;
            }
        }

        .alertConnectSpotify {  
            position: absolute;
            cursor: pointer !important;
            z-index: 999;
            top: 10% !important;
            height: 75%;
            max-height: 240px;
            align-items: center;
            width: 80% !important;
            left: 10%;
            opacity: 0.99;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .iconOverPlayer { // icon when playing change
        position: absolute;
        top: 43%;
        z-index: 1;
        width: 100%;
        text-align: center;
        svg {
            transform: scale(3.2);
            background:rgba(var(--grey-dark-rgb),0.9);
            border-radius: 50%;

            &.blinked_animate {
                opacity: 0;
                animation: typedjsBlinkReverse 1s;
                -webkit-animation: typedjsBlinkReverse 1s;
                animation: typedjsBlinkReverse 1s;
            }
        }

    }
    /*
    *
    * RIGHT SIDE CONTAINER (BOTTOM ON PHONE) = VIDEO / MEDIA INFOS
    *
    */
    .player_right_side_container {
        display: flex;
        flex-direction: column !important;
        justify-content: space-between;
        height: auto !important;
        background-image: url('../img/circles.png');
        background-size: contain;
        
        /*
        *
        * BUTTONS CONTAINER
        *
        */
        .player_button_container {
            width: 100%;
            >div {
                background: rgba(2, 2, 2, 0.76);
                width: 100%;
                padding: 5px 10px;
            }
            .playerButtons {
                justify-content: space-between;
                margin: 0;
                width: 100%;  
                max-width: 100%;
                >* {
                    padding: 0;    
                    transform: scale(0.9);
                    
                }
                >div {
                    margin-left: -10px;

                }
                .mediaPlayingBar {
                    transform: scale(1);
                    position: absolute;
                    height: 3px;                    
                    transition: all 0.3 var(--transition-fast-easeIn);
                    width: 100%;
                    left: 0;
                    top: -3px;
                        background: var(--grey-dark);   

                    >span {
                        background: var(--main-color);    
                    }
                }
                
            }
            .volumeButtonContainer {
                background: transparent;
                >label,
                >label>span {
                    margin: 0;
                }
            }
            
        }
    }
}



@media (max-width: 600px) { 
    .playerSection {
        .playerContainer {
            position: relative;

            &:hover {
                +.player_right_side_container .player_button_container {
                    .playerButtons .mediaPlayingBar {
                        height: 5px;
                        top: -5px;
                    }
                    .playerButtons .mediaPlayingBarSoundWave {
                        top: -17px;
                        height: 12px;
                    }
                    >div {
                        background: rgba(2, 2, 2, 0.9);
                    }
                }
            }

            .react-player + div {
                height: calc(100% - 45px);
            }
        }
    }
}


.guestView {
    .playerSection .player_right_side_container .player_button_container .playerButtons {
        justify-content: space-between;
        gap:20px;
    }
}