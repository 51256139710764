.userAvatarBig {
    background: #cecece;
    width: 110px;
    padding: 12px;
    height: 110px;
    outline-style: dashed;
    outline-width: 7px;
    display: flex;
    margin: 1em auto 1em auto;
    border-radius: 50%;
    -webkit-animation: rotate-scale-up 1s ease-in-out both;
            animation: rotate-scale-up 1s ease-in-out both;
    svg{
        width: 100%;
        
    }

    >div {    
      animation: bounceCustom 5s; /* Durée de l'animation */
      animation-delay: 5s; /* Délai avant chaque répétition */
      animation-iteration-count: infinite; /* Répétition infinie */
    }
}

.userInfosList .small span {
    font-size: 0.8em;
}