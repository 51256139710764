.roomTutorialContainer {
    >div { 
        border-bottom: 2px dashed rgba(106, 173, 245, 0.79);
        border-left: 2px dashed rgba(0, 123, 255, 0.79);
        border-right: 2px dashed rgba(0, 123, 255, 0.79);
        background: linear-gradient(to top, rgba(0, 123, 255, 1), rgba(0, 123, 255, 0));
        height: 20vh;
        min-height: 200px;  
        padding-top: 3em;
    }  
    position: fixed;
    bottom: 0; 
    width: 100%;
    text-align: center;
    
    p {
        font-weight: 500;
        color:white;
        text-shadow: 2px 2px 6px rgba(0,0,0,1), 0px -5px 35px rgba(0,0,0,1);
    }

    .SlideUp {
        font-size: 2em;
        color: white;
         -webkit-animation: slide-top 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
            animation: slide-top 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
    }

    .ClickUp {
        margin-top: 20px;
        font-size: 3em;
        margin-left: -10px;
        color: white;
         -webkit-animation: scale-up-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
            animation: scale-up-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
    }

    .desktop {
        display: none;
    }
    
    .phoneAndTablet {
        background: linear-gradient(to top, rgba(0, 123, 255, 1) 40%, rgba(0, 123, 255, 0));
    }
}

@media (min-width: 900px) { 
    .roomTutorialContainer {
        .phoneAndTablet {
            display: none;
        }
        .desktop {
            display: block;
        }
    }
}