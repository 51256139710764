
.autowriter_container {

    display: flex;
    flex-direction: row;
    span{
        flex-grow: 1 !important;
    }

    #typed-cursor {
        width: 3px;
        height: 55px;
        background: var(--main-color);
        position: absolute;
        left: 15px;
        top: 12px;
        animation: typedjsBlink 1s infinite;
        -webkit-animation: typedjsBlink 1s infinite;
        animation: typedjsBlink 1s infinite;
    }
    .input_big {
        font-size: 2em;
        caret-color: transparent;
        color:var(--white) !important;
        background-color:var(--main-bg-color) !important;
        width: 100%; 
        flex-grow: 1;
        padding-right:0;
        border:2px solid var(--border-color);
        padding-left: 15px;
        height: 100%;

        ::placeholder {
            color:var(--white) !important;
        }
    }
}

.closeAddMediaModal {
    box-shadow: -1px -3px 20px 2px rgba(0, 0, 0, 0.42);
    -webkit-box-shadow: -1px -3px 20px 2px rgba(0, 0, 0, 0.42);
    -moz-box-shadow: -1px -3px 20px 2px rgba(0, 0, 0, 0.42);
    background-color: #202124;
    padding-bottom: 0;
    cursor:pointer;
    flex-flow: nowrap;  
    flex-wrap: nowrap !important;
    overflow: hidden;
    position:absolute;
    bottom:0;
    z-index:100;
    .modal_full_screen_close_left {
        background-color: var(--main-color-darker) !important;
        border-radius: 0 !important;
        margin-right: 10px !important;
    }
}


@media (max-width: 900px) { 
    .autowriter_container {
        .input_big input, .input_big.typed,.input_big.typed:focus {
            font-size: 1em;
        }
        
        #typed-cursor {
            width: 2px;
            height: 35px;
            top: 21px;    
        }
    }
}

.searchResultItem {
    >div {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    width: 100%;
    .resultBoxContent {
        background: linear-gradient(to bottom, rgba(250, 250, 250, 0.7), rgba(255, 255, 255));
    }

    .MuiCardMedia-root {
        background-size: contain;
        background-color: white;
    }
}

.youtubeSlider {

    overflow: hidden;
    .use-spring-carousel-item {
        min-width: 130px;
        padding: 0 5px;
    }

    .carouselControls {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5em;
        button {
            background: rgba(0, 0, 0, 0.6)
        }
    }
}
