@use 'var.scss';
@use 'font.scss';
@use 'animations.scss';
@use 'globalStyle.scss';
@use 'home.scss';

@use 'mainTopBar.scss';

@use 'playlist.scss';
@use 'soundWave.scss';

@use 'player.scss';
@use 'playersParticularity.scss';

@use 'roomBottomSection.scss';
@use 'modalShareRoom.scss';
@use 'modalJoinRoom.scss';
@use 'modalAddMedia.scss';
@use 'modalUserProfile.scss';

@use 'layout.scss';

@use 'playlistList.scss';
@use 'tutorial.scss';

.votebuttons > button {
    padding: 2px !important;
    min-width: 50px;
}

.roomPlaylistbloc  .MuiLinearProgress-root {
    background-color: #786b4d !important;
}
.MuiListItemButton-root.Mui-selected:hover {
    background-color: var(--main-bg-color) !important;
}


.black_style_dialog>.MuiPaper-root {
    background:var(--main-bg-color);
    background-image:url('../img/textura.png');
    background-size: cover;
    background-position: 50% 50%;
}

.full_width_modal_content_container {
    overflow-y: scroll !important;
    padding: 3em;
    padding-top:0;
    min-height: 100vh;
    max-width: 100vw;
    z-index: 3;
}

@media (max-width: 900px) { 

    .spotify_header .player_button_container>div {
        margin: 0 auto !important;
        max-width: 80%;
    }

    .full_width_modal_content_container {
        padding: 0 !important;  
    }

    .video_title_list span {
        font-size: 0.8em;
    }
    .hidden-xs {
        display: none !important;
    }
}

.interactionImageContainer {
    position: fixed;
    top: 70vh;
    width: 25px;
    height: auto;
    opacity: 0;
    transform: translateY(0) translateX(0);
    z-index: 25000;
    animation: fall 3s linear forwards;
}


.icon_overlay {
    position: absolute;
    width: 70%;
    opacity: .6;
    transform: rotate(0deg);
    animation : rotate 4s ease-in-out infinite;
}

.rotating_animate {
    animation : rotate 1s ease-in-out infinite;
}


.loadingRoomInfo {
    text-align: center;
    color: white;
    position: fixed;
    top: 46%;
    width: 100%;
    padding: 2em;
    
    p {
        z-index: 2;
        top: 12px;
        position: relative;
        text-shadow: 2px 2px 6px rgba(0,0,0,1), 0px -5px 35px rgba(0,0,0,1);
    }
    .soundWaveContainer {
        z-index: 1;  
        position: absolute;
        transform: scale(2);  
        height: 55px;
        
        .box {
            background: linear-gradient(to top, rgba(0, 123, 255, 0.5) 10%,rgb(0, 123, 255) 50%,rgb(0, 123, 255) 70%, rgba(0, 123, 255, 0.5));
        }
    }
}