@keyframes typedjsBlinkReverse { /* for the typing effect in the search bar */
    50% {
        opacity: 1;
    }
}

@-webkit-keyframes typedjsBlinkReverse {  /* for the typing effect in the search bar */
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
@keyframes typedjsBlink { /* for the typing effect in the search bar */
    50% {
        opacity: 0.0;
    }
}

@-webkit-keyframes typedjsBlink {  /* for the typing effect in the search bar */
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.0;
    }

    100% {
        opacity: 1;
    }
}
   
@keyframes fall { /* for the interactions */
    5% {
        opacity: 1;
    }
    to {
        transform: translateY(101vh) translateX(15vw);
    }
}

@keyframes rotate { /* for the time cooldown */
    45% {
        transform: rotate(180deg);
    }
    55% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@keyframes angleRotate {
  to {
    --angle: 360deg;
  }
}

@keyframes quiet { /* for the soundwave animation */
  25%{
    transform: scaleY(.6);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.8);
  }
}
@keyframes normal { /* for the soundwave animation */
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.6);
  }
}
@keyframes loud { /* for the soundwave animation */
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(1.2);
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2024-6-3 0:36:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-3 1:2:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-3 1:28:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-down-out {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
  }
}
@keyframes slide-down-out {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-9 17:6:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(0.2) rotateY(0);
            transform: scale(0.2) rotateY(0);
  }
  20% {
    -webkit-transform: scale(0.5) rotateY(180deg);
            transform: scale(0.5) rotateY(180deg);
  }
  40% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
  60% {
    -webkit-transform:  rotateY(180deg);
            transform:  rotateY(180deg);
  }
  80% {
    -webkit-transform:  rotateY(360deg);
            transform:  rotateY(360deg);
  }
}

@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(0.2) rotateY(0);
            transform: scale(0.2) rotateY(0);
  }
  20% {
    -webkit-transform: scale(0.5) rotateY(180deg);
            transform: scale(0.5) rotateY(180deg);
  }
  40% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
  60% {
    -webkit-transform:  rotateY(180deg);
            transform:  rotateY(180deg);
  }
  80% {
    -webkit-transform:  rotateY(360deg);
            transform:  rotateY(360deg);
  }
}



@-webkit-keyframes bounceCustom {
  from,
  23%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  13% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  40% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  50% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  60% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
   transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
  100% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
   transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}
@keyframes bounceCustom {
   from,
  23%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  13% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  40% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  50% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  60% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
   transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
  100% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
   transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-7-1 23:45:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation ping
 * ----------------------------------------
 */
@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}

.ping {
	-webkit-animation: ping 1.5s ease-in-out infinite both;
	        animation: ping 1.5s ease-in-out infinite both;
}