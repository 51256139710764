
:root{
  --main-bg-color: #4f4f4f;
  --main-bg-color-hexa: 79, 79, 79;
  --border-color: #3e464d;
  --main-color-lighter:rgb(116, 182, 252);
  --main-color: rgb(83, 164, 250);
  --main-color-transp:rgba(83,164,250, 0.8);
  --main-color-darker: rgb(3, 104, 212);
  --red:#c63b3b;
  --red-2:#ff5722;
  --orange:#ffb375;
  --green:#2e7d32;
  --green-2:#1cbd24;
  --green-light:#4cdd53;
  --white:#efeff1;
  --blue-light:rgba(0, 123, 255);
  --grey-lighter:rgb(217, 217, 228);
  --grey-light:rgb(173, 173, 184);
  --grey-inspired: rgb(167, 167, 167);
  --grey-dark:#262626;
  --grey-dark-rgb:38, 38, 38;
  --grey-darker:#141414;
  --transition-fast-easeIn:all 0.1s ease-in;
  --transition-smooth-all:all 0.3s ease-out;
  --main-typo-family:'Lato', sans-serif;
}