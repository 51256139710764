.modal_share_room {

    .makeStyles-container-1 {
        padding: 0 !important;
    }

    .modal_share_room .makeStyles-copyContainer-5 {
        display: none !important;
    }
}
