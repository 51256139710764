/*
*
*  SPOTIFY
*
*/

._ControlsRSWP, ._DevicesRSWP, ._InfoRSWP a+div, ._ActionsRSWP, .PlayerRSWP, ._ContentRSWP, ._SliderRSWP  {
display: none !important;
}

._InfoRSWP {
    border:none !important;
    a {
        width: 100%;
        display: block;
    }
}