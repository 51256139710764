
@use 'mixins';

.topBarIsInRoom {
    background: none !important;
    position: absolute !important;
    top: 0;  
    width: auto !important;
    z-index: 500;
    right: 0;
    box-shadow: none !important;

    img {
        display: none;
    }
    
    .MuiIconButton-sizeSmall {
        padding: 0;
    }

    .MuiToolbar-root {
        height: 45px;
        min-height: 0;
    }
}

.appBar {
    .appLogo {
        width:auto;
        max-width:50%;
        max-height:40px;
        margin-top:2px;

        @include mixins.respond-to(xs) {
            max-height:35px;
        }

    }

    .loginButtonTop ,
    .loginButtonTop *{
        color: var(--white);
    }
    
}

.userAvatar {
    height: 30px;  
    outline-style: dashed;
    width: 30px;
    outline-width: 2px;
    background: rgb(117, 107, 107);
    border-radius: 50%;
    padding: 4px;
}

.inputLoadingSvg svg{
    height: 40px;
    width: 40px;
    margin: -10px;
}

.stickyRoomTopBar {
    padding-right: 10px !important;
    box-shadow: 10px 5px 10px -1px rgba(0, 0, 0, 0.8),0px 10px 50px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    
    .stickyButtons {
        display: flex;
        gap: 5px;
        >* {
            padding: 0;
            transform: scale(1);
        }
        .volumeButtonContainer {
            transform: scale(0.8);
            margin-right: -10px;
        }
    }

    .appLogo {
        display: none;
    }
}
