.contentSlider {
    max-width: 100%;
    cursor: grab;

    .slideBox {
        opacity: 0.2;
        padding: 0.5em 0 2em 0;
        background: var(--grey-dark);
        border-radius: 5px;
        transform: scale(0.7);
        transition: var(--transition-fast-easeIn);
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &.activeSlideBox {
            opacity: 1;
            transform: scale(1);
            transition: var(--transition-fast-easeIn);
        }
        h5 {
            font-size: 1.6em;
            text-align: center;
            margin-bottom: 0;
            color: var(--white) !important;
        }

        img {
            filter: hue-rotate(180deg) saturate(1000%);

            &.mainIcon {
                width: 150px;
                margin-bottom: 1em;
                transform: none;
                margin-left: auto;
                z-index: 2;
                margin-right: auto;

                &.altIcon {
                    width: 116px;
                    top: 110px;
                    z-index: 1;
                    opacity: 0.5;
                    left: 20%;
                    position: absolute;

                    &.left {
                        left: 30%;
                    }
                    &.middle {
                        top:80px;
                    }
                    &.top {
                        top:40px;
                    }
                    &.small {
                        width: 56px;
                    }
                    &.right {
                        left: 60%;
                    }
                }
            }
        }
    }
}

.texturaBgButton,.leftDrawerTop  {
    background-image: url('../img/textura.png');
    background-size: 100%;
}

.main_container {
    background-image: url('../img/textura_white.png');
    background-size: cover;
    background-position:50%;
}

.loginPhoneModal {
    .MuiPaper-root, .MuiDialogContent-root {
        overflow: visible;
    }

    .phoneField .MuiInputAdornment-root {
        height: 0;
    }
}

@media (max-width: 900px) { 
    .contentSlider .slideBox{
        h5 {
            font-size: 1.3em;
        }
        img.mainIcon {
            width: 120px;
            &.altIcon {
                &.left {
                    left: 15%;
                }
                &.right {
                    left: 75%;
                }
            }
        }  
    }
}


@media (max-width: 900px) { 
    .contentSlider .slideBox{
        h5 {
            font-size: 1.3em;
        }
        img.mainIcon {
            width: 120px;
            &.altIcon {
                &.left {
                    left: 5%;
                }
                &.right {
                    left: 80%;
                }
            }
        }  
    }
}